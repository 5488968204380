<script>
	import { navigation } from '../utilities/store';
	import DesktopNavList from "./DesktopNavList.svelte";
	export let target;

	//const langNavigation = target.children[0];
	const mainNavigation = target.children[2];
	//const navList = mainNavigation.children[0].children;
  const navList = (target.children[2]) ? mainNavigation.children[0].children : [];

  $navigation = mainNavigation;
  mainNavigation.remove();

</script>

<DesktopNavList {navList}/>
