<script>

	export let navList = false;
	export let navItems = walkNavItems(Array.from(navList));
	export let depth = 0;

	let isOpen = [];

	for( let i = 0; i < 10; i++) {
		isOpen[i] = true;
	}

	function walkNavItems(navArray) {
		return navArray.map((item) => {
			let submenu = (item.classList.contains('menu-item-has-children')) ? Array.from(item.children[1].children) : [];
			return {
				title: item.children[0].textContent,
        href: item.children[0].getAttribute('href'),
        submenu: walkNavItems(submenu)
      }
    });
  }

	let toggleSubmenu = (index) => isOpen[index] = !isOpen[index];

</script>

<ul class="level-{depth}">
  {#each navItems as navItem, index}
    <li class:has-submenu={navItem.submenu.length !== 0}>
      <a href="{navItem.href}" class="menu-item-link">{navItem.title}</a>
      {#if navItem.submenu.length !== 0}
        <button on:click={() =>toggleSubmenu(index)} class:show={isOpen[index]}>
          <span class="sr-only">Untermenü öffen/schließen</span>
          <i class="icon-chevron-right"></i>
        </button>
        <div class="submenu" class:show={isOpen[index]}>
          <svelte:self navItems={navItem.submenu} depth={depth + 1} navList={null} />
        </div>
      {/if}
    </li>
  {/each}
</ul>

