<script>
	import { openNavState, navigation } from '../utilities/store';
	import MobileNavigation from "./MobileNavigation.svelte";
	const navList = $navigation.children[0].children;
</script>

{#if $openNavState}
  <div class="mobile-nav-container">
    <nav class="mobile-navigation">
      <MobileNavigation {navList} />
    </nav>
  </div>
{/if}
