<script>
	import { isMobile, openNavState } from '../utilities/store';
	import MobileNavContainer from "./MobileNavContainer.svelte";

	export let target;

	const contentContainer = target.querySelector('#content');

  $: {
  	($openNavState) ? contentContainer.classList.add('is-open') : contentContainer.classList.remove('is-open');
  	if (!$isMobile) {
			contentContainer.classList.remove('is-open');
		}
	}

</script>

{#if $isMobile}
  <MobileNavContainer/>
{/if}
