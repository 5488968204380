<script>
	import { isMobile, openNavState } from '../utilities/store';

	let isOpenNav = false;

	const toggleNav = (e) => {
		e.preventDefault();
		isOpenNav = !isOpenNav;
		$openNavState = isOpenNav;
	}

	const closeNav = () => {
		isOpenNav = false;
		$openNavState = isOpenNav;
  }

	$: {
		if (!$isMobile) {
			closeNav();
    }
	}
</script>

<!-- <button class="nav-toggle" on:click={(e) => toggleNav(e)}></button> -->

<a href="#" class="nav-toggle" on:click={(e) => toggleNav(e)} role="button">
  <span></span>
  <span></span>
  <span></span>
</a>
