<script>
export let target; // -> form element

const acceptBox = target.querySelector('#acceptBox');
const submitter = target.querySelector('#submitter');

acceptBox.addEventListener("click", (e) => {
  (e.target.checked) ? submitter.removeAttribute('disabled') : submitter.setAttribute('disabled', 'disabled');
});

target.addEventListener("submit", () => {
  return acceptBox.setAttribute('checked');
});

</script>
