<script>

	const languageNavigation = document.getElementById('languageNavigation');
  const navList = languageNavigation.children[0].children;

	const items = Array.from(navList
  ).map((item) => {
		return {
			liClasses: item.className,
			innerText: item.children[0].innerText,
			href: item.children[0].href,
			classes: item.children[0].className,
			title: item.children[0].getAttribute('title'),
			hreflang: item.children[0].getAttribute('hreflang'),
			lang: item.children[0].getAttribute('lang')
		}
	});
</script>

<nav class="header-language-navigation lang-nav">
  <ul>
    {#each items as item}
      <li class="{item.liClasses}">
        <a href={item.href} title={item.title} class={item.classes} hreflang={item.hreflang} lang={item.lang}>
          <span>{item.innerText}</span>
        </a>
      </li>
    {/each}
  </ul>
</nav>
