<script>
  export let target;

  let innerWidth = window.innerWidth;

  let colWidths = [];

  const firstCol = target.children[0];
  const secondCol = target.children[1];

  // get first image element of second column
  const firstImageSecondCol = secondCol.getElementsByClassName('wp-block-image')[0];

  if (firstImageSecondCol !== undefined) {
    firstImageSecondCol.classList.add('teaser-image');
  }

  // get values of flex-basis styles, transform to array with fr-values, and remove style attribute
  Array.from(target.children).forEach((item, i) => {
    colWidths[i] = window.getComputedStyle(item).getPropertyValue('flex-basis').replace(/%/gi, "fr");
    item.removeAttribute('style');
  });



  // create style attribute for grid-template columns depending on viewport width
  $: {

    if (innerWidth >= 880) {
      target.setAttribute('style', 'grid-template-columns:' + colWidths.join(' '));
    }
    else {
      target.removeAttribute('style');
    }

    // manage the first image of second column depending on viewport size
    if (firstImageSecondCol !== undefined) {
      firstImageSecondCol.remove();
      if (innerWidth < 880) {
        firstCol.insertBefore(firstImageSecondCol, firstCol.children[0]);
      }
      else {
        secondCol.insertBefore(firstImageSecondCol, secondCol.children[0]);
      }
    }
  }

</script>

<svelte:window bind:innerWidth />
